import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import DatePicker from "../components/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import axios from "axios";


function truncateToDecimals(num, dec = 1) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }

function EmissionCo() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  let defaultValue = "";
  const selectAllOption = { label: "Sélectionnez tout", value: "*" };
  const options = [];
  function setOptions() {
    const listMachines = JSON.parse(localStorage.getItem("machines"));
    Object.keys(listMachines).map((index) => {
      var details = {
        value: listMachines[index].machine_id,
        label: listMachines[index].machine_name,
      };
      options.push(details);
    });
  }

  setOptions();
  const [selected, setSelected] = useState({
    machines: [],
    annee_fin: "",
    mois_fin: "",
    jour_fin: "",
    annee_debut: "",
    mois_debut: "",
    jour_debut: "",
  });
  const initialVisibleOptions =
    options.length === defaultValue?.length
      ? options
      : [selectAllOption, ...options];
  const [availableOptions, setAvailableOptions] = useState(
    initialVisibleOptions
  );
  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const [selectedVal, setSelectedVal] = useState(selected.machines);

  const [selectedDate, setSelectedDate] = useState({
    fin: "",
    debut: "",
  });

  const [dataEmission, setDataEmission] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCard = () => {
    setShowCard(true);
    setIsLoading(false);
  };
  const handleChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find(
      (o) => o.value === selectAllOption.value
    );

    const newComponentState = selectAllIsSelected
      ? {
          selectedValues: options,
          availableOptions: [],
        }
      : {
          selectedValues: newSelectedOptions,
          availableOptions: initialVisibleOptions,
        };

    setSelectedValues(newComponentState.selectedValues);
    setAvailableOptions(newComponentState.availableOptions);
    // this.onChange(newComponentState.selectedValues);
  };

  function isLater(dateString1, dateString2) {
    return new Date(dateString1) > new Date(dateString2)
  }

  
  const handleErrors = async () => {
    const date_deb = selected.annee_debut +"-" + selected.mois_debut + "-" + selected.jour_debut;
    
    const date_fin = selected.annee_fin + "-" + selected.mois_fin +"-"+ selected.jour_fin;

    if (Object.keys(selectedVal).length === 0) {
      toast.error("Veuillez sélectionner au moins une machine");
    } else if (selected.annee_debut === "") {
      toast.error("Veuillez sélectionner date de début");
    } else if (selected.annee_fin === "") {
      toast.error("Veuillez sélectionner date de fin");
    } else if (isLater(date_deb , date_fin)) {
      toast.error("La date de début doit être anterieur a la date fin");
    }
     else {
      setIsLoading(true);
        var body = {
          year: selected.annee_debut,
          month: selected.mois_debut,
          day: selected.jour_debut,
          year1: selected.annee_fin,
          month1: selected.mois_fin,
          day1: selected.jour_fin,
          ids_machine: selectedVal,
        };
     
      try {

        await axios.post(
        
            process.env.REACT_APP_BACKEND_IP +
            ":" +
            process.env.REACT_APP_BACKEND_PORT +
            "/emission/getDataEmissionCo/",
            {
              headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
              }
            },
          body)
          .then(res => {
           
            if (res.data.status === "ok"){
              setDataEmission(res.data.calculEmission);
              handleCard();
              setIsLoading(false);
            }
          })
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDateChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
    if (type == "fin") {
      setSelected((prevState) => ({
        ...prevState,
        annee_fin: year,
        mois_fin: month,
        jour_fin: day,
      }));
    } else {
      setSelected((prevState) => ({
        ...prevState,
        annee_debut: year,
        mois_debut: month,
        jour_debut: day,
      }));
    }
  };





  useEffect(() => {
    setSelectedVal(
      Object.keys(selectedValues).map(
        (element) => selectedValues[element].value
      )
    );
  }, [selectedValues, dataEmission]);
  const ResultEmission = () => {
    return (
      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
            <h2 className="mb-2 fw-semibold text-card-energy">{truncateToDecimals(dataEmission.co2Emissions)} KgCO₂ </h2>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="emissionCo2" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Emission CO₂</h1>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Sélectionnez vos paramètres </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Machines
                      </label>
                      <div className="form-group col-md-10 col-lg-10 col-xl-11">
                        <SelectBox
                          options={availableOptions}
                          value={selectedValues}
                          defaultValue={selectedValues}
                          onChange={handleChange}
                          placeholder={"Selectionnez machine(s)..."}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <label className="form-label col-md-3 col-lg-3 col-xl-1">
                        Date
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-4 ">
                        <DatePicker
                          id={"dateDebut"}
                          value={selectedDate.debut}
                          onChange={(e) => handleDateChange(e, "debut")}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-4 ">
                        <DatePicker
                          id={"dateFin"}
                          value={selectedDate.fin}
                          onChange={(e) => handleDateChange(e, "fin")}
                        />
                      </div>

                 
                  

                      <div className="col-md-12 col-lg-12 col-xl-3">
                        <button
                          className="btn btn-outline-primary btn-block"
                          type="submit"
                          onClick={(e) => handleErrors()}
                        >
                          <i className="fa fa-calculator me-2"></i>
                          Calculer
                        </button>
                     
                        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {   isLoading ? ( 
                          <BouncingDotsLoader/>
                  ) : showCard ? <ResultEmission /> : <></>}
              </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default EmissionCo;
