import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "../components/ErrorBoundary";

function GestionUtilisateur() {
  const navigate = useNavigate();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  };
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let dataUser;
      try {
        dataUser = await axios.get(
          process.env.REACT_APP_BACKEND_IP +
          ":" +
          process.env.REACT_APP_BACKEND_PORT +
          "/users",
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        );
        setUserData(dataUser.data.users);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const handleDelete = (user) => {
    setSelectedUser(user);
    openModal();
  };

  const cancelDelete = () => {
    setSelectedUser(null);
    closeModal();
  };
  const deleteUser = async () => {
    if (selectedUser) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/users/delete_user/${selectedUser}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        );

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/users`,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        );

        setUserData(response.data.users);
        toast.success("Utilisateur supprimé avec succès");
      } catch (error) {
        console.log(error);

        toast.error("Erreur lors de la suppression de l'utilisateur");
      }
    }
    setSelectedUser(null);
    closeModal();
  };

  const handleAdd = () => {
     navigate("/ajoutUtilisateur")
  }

  function tableBody(data) {
    if (Object.keys(data).length > 0) {
      return (
        <table
          id="basic-datatable"
          className="table table-bordered table-vcenter text-nowrap mb-0 w-100"
        >
          <thead>
            <tr>
              <th className="border-bottom-0">Nom Utilisateur</th>
              <th className="border-bottom-0">Email</th>
              <th className="border-bottom-0">Role</th>
              <th className="border-bottom-0 w-14">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((key) => {
              return (
                <tr key={data[key]["_id"]}>
                  <td>{data[key]["nom_utilisateur"]}</td>
                  <td>{data[key]["email"]}</td>
                  <td>{data[key]["role"] == 0 ? "Admin" : "Utilisateur"}</td>
                  <td>
                    <button
                      className="btn btn-danger btn-spinners ms-2"
                      type="submit"
                      onClick={() => handleDelete(data[key]["_id"])}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  return (
    <>
    <ErrorBoundary>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionUtilisateurs" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Gestion Utilisateurs</h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                        <h3 className="card-title">Listes des utilisateurs</h3>
                      
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleAdd()}
                        >
                          Ajouter Utilisateur
                        </button>
                      
                      </div>



                      <div className="card-body">
                        <div className="table-responsive">
                          <LazyLoad once>
                            {Object.keys(userData).length > 0 ? (
                              tableBody(userData)
                            ) : (
                              <></>
                            )}
                          </LazyLoad>
                        </div>

                        <Modal
                          isOpen={isModalOpen}
                          onRequestClose={closeModal}
                          contentLabel="Suppression Utilisateur"
                          style={customStyles}
                        >
                          <h4>Confirmer Suppression </h4>
                          <p>
                            Êtes-vous sûr de vouloir supprimé l'utilisateur ?
                          </p>
                          <button
                            className="btn btn-outline-primary btn-block "
                            onClick={() => deleteUser()}
                          >
                            confirmer
                          </button>
                          <button
                            className="btn btn-outline-danger btn-block"
                            onClick={cancelDelete}
                          >
                            Annuler
                          </button>
                        </Modal>
                        <ToastContainer
                          className="toast-container"
                          autoClose={2000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      </ErrorBoundary>
    </>
  );
}
export default GestionUtilisateur;
